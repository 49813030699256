import { motion, useInView } from 'framer-motion';
import { Box } from '@chakra-ui/react';
import { useRef } from 'react';

const MotionBox = motion(Box);

export default function MotionWrapper({ children, delay = 0 }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, margin: "-100px" });

  return (
    <MotionBox
      ref={ref}
      initial={{ opacity: 0, y: 30 }}
      animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
      transition={{ duration: 0.7, delay }}
      whileHover={{ y: -5 }}
    >
      {children}
    </MotionBox>
  );
}
