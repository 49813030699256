import React from 'react';
import {
  Box,
  Container,
  VStack,
  Heading,
  Text,
  Button,
  SimpleGrid,
  Icon,
  HStack,
  useColorModeValue,
  Badge,
  Stack,
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Avatar,
  List,
  ListItem,
  ListIcon,
  Divider,
  Image
} from '@chakra-ui/react';
import { FiSend, FiBox, FiLayers, FiArrowRight, FiCheck, FiShield, FiGlobe, FiCpu, FiUsers, FiCalendar, FiBook, FiHeart, FiBriefcase } from 'react-icons/fi';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

// Import helper components
import StatBox from './components/StatBox';
import StepCard from './components/StepCard';
import UseCaseCard from './components/UseCaseCard';
import TestimonialCard from './components/TestimonialCard';
import PricingCard from './components/PricingCard';
import FAQ from './components/FAQ';
import GuaranteeSection from './components/GuaranteeSection';
import Navigation from './components/Navigation';
import Footer from '../components/Footer';
import TestimonialCarousel from './components/TestimonialCarousel';

const MotionBox = motion(Box);

export default function Landing() {
  const navigate = useNavigate();
  const bgGradient = useColorModeValue(
    'linear(to-br, blue.50, white, blue.50)',
    'linear(to-br, gray.900, gray.800)'
  );
  
  return (
    <Box>
      <Navigation />
      
      {/* Hero Section */}
      <Box
        bgGradient={bgGradient}
        minH={{ base: "auto", md: "90vh" }}
        py={{ base: 8, sm: 12, md: 16, lg: 20 }}
        px={{ base: 4, sm: 6, md: 8 }}
        position="relative"
        overflow="hidden"
      >
        <Container maxW="container.xl">
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing={{ base: 10, lg: 20 }}
            align={{ base: "center", lg: "center" }}
            justify="space-between"
            w="full"
          >
            <VStack 
              align={{ base: "center", lg: "start" }} 
              spacing={{ base: 5, md: 6 }}
              maxW={{ base: "100%", lg: "600px" }}
              textAlign={{ base: "left", lg: "left" }}
            >
              <Flex 
                gap={{ base: 2, md: 3 }} 
                flexWrap="wrap" 
                justify="flex-start"
                w="full"
              >
                <Badge colorScheme="blue" fontSize={{ base: "xs", md: "sm" }} px={3} py={1} borderRadius="full">
                  Forms & Logging
                </Badge>
                <Badge colorScheme="green" fontSize={{ base: "xs", md: "sm" }} px={3} py={1} borderRadius="full">
                  Telegram Integration
                </Badge>
                <Badge colorScheme="purple" fontSize={{ base: "xs", md: "sm" }} px={3} py={1} borderRadius="full">
                  Uptime Monitoring
                </Badge>
              </Flex>
              
              <Heading
                size={{ base: "xl", sm: "2xl", md: "3xl" }}
                bgGradient="linear(to-r, blue.400, blue.600)"
                bgClip="text"
                lineHeight={{ base: "shorter", md: "shorter" }}
              >
                Forms, Logging & More, United in Telegram
              </Heading>
              
              <Text 
                fontSize={{ base: "md", sm: "lg", md: "xl" }} 
                color="gray.500"
                maxW={{ base: "100%", md: "90%" }}
              >
                Create forms and monitor apps in one place. Get instant custom Telegram notifications 
                e.g customer feedback, server events, and uptime monitoring.
              </Text>
              
              <Box alignSelf="flex-start">
                <Button
                  size="md"
                  colorScheme="blue"
                  rightIcon={<FiArrowRight />}
                  onClick={() => navigate('/register')}
                >
                  Get Started Free
                </Button>
              </Box>
            </VStack>

            <MotionBox
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              w="full"
              maxW={{ base: "100%", md: "90%", lg: "600px" }}
            >
              <SimpleGrid 
                columns={{ base: 1, sm: 2, lg: 2 }} 
                spacing={{ base: 3, sm: 4 }}
                mx={{ base: 0 }}
              >
                <Box
                  p={{ base: 3, md: 6 }}
                  borderRadius="xl"
                  boxShadow="xl"
                  border="1px"
                  borderColor={useColorModeValue('gray.100', 'gray.700')}
                  bg={useColorModeValue('white', 'gray.800')}
                >
                  <VStack spacing={{ base: 2, md: 3 }} align="stretch">
                    <Text fontSize={{ base: "sm", md: "lg" }} fontWeight="bold">
                      Form Response
                    </Text>
                    <Box 
                      p={{ base: 2, md: 4 }} 
                      bg={useColorModeValue('gray.50', 'gray.700')} 
                      borderRadius="md"
                      fontSize={{ base: "xs", md: "md" }}
                    >
                      <Text>📝 New Form Submission</Text>
                      <Text>Form: Product Feedback</Text>
                      <Text>Rating: ⭐⭐⭐⭐⭐</Text>
                      <Text>Feature Request: "Dark mode would be great!"</Text>
                    </Box>
                  </VStack>
                </Box>

                <Box
                  p={{ base: 3, md: 6 }}
                  borderRadius="xl"
                  boxShadow="xl"
                  border="1px"
                  borderColor={useColorModeValue('gray.100', 'gray.700')}
                  bg={useColorModeValue('white', 'gray.800')}
                >
                  <VStack spacing={{ base: 2, md: 3 }} align="stretch">
                    <Text fontSize={{ base: "sm", md: "lg" }} fontWeight="bold">
                      Uptime Alert
                    </Text>
                    <Box 
                      p={{ base: 2, md: 4 }} 
                      bg={useColorModeValue('gray.50', 'gray.700')} 
                      borderRadius="md"
                      fontSize={{ base: "xs", md: "md" }}
                    >
                      <Text>🔄 Performance Issue Detected</Text>
                      <Text>Service: API Endpoint</Text>
                      <Text>Issue: High Response Time</Text>
                      <Text>Analysis: Memory usage spike detected</Text>
                    </Box>
                  </VStack>
                </Box>

                <Box
                  p={{ base: 3, md: 6 }}
                  borderRadius="xl"
                  boxShadow="xl"
                  border="1px"
                  borderColor={useColorModeValue('gray.100', 'gray.700')}
                  bg={useColorModeValue('white', 'gray.800')}
                >
                  <VStack spacing={{ base: 2, md: 3 }} align="stretch">
                    <Text fontSize={{ base: "sm", md: "lg" }} fontWeight="bold">
                      Error Monitoring
                    </Text>
                    <Box 
                      p={{ base: 2, md: 4 }} 
                      bg={useColorModeValue('gray.50', 'gray.700')} 
                      borderRadius="md"
                      fontSize={{ base: "xs", md: "md" }}
                    >
                      <Text>⚠️ Error Detected</Text>
                      <Text>Type: Database Connection</Text>
                      <Text>Status: Resolved</Text>
                      <Text>Recovery Time: 45s</Text>
                    </Box>
                  </VStack>
                </Box>
              </SimpleGrid>
            </MotionBox>
          </Stack>
        </Container>
      </Box>

      {/* Stats Section */}
      <Box bg={useColorModeValue('gray.50', 'gray.900')}>
        <Container maxW="container.xl" py={16}>
          <SimpleGrid columns={{ base: 1, md: 4 }} spacing={8}>
            {[
              { label: "Active Users", number: "2,000+", helpText: "Trusted by developers" },
              { label: "Forms Created", number: "5,000+", helpText: "And counting" },
              { label: "Response Rate", number: "99.9%", helpText: "Delivery success" },
              { label: "Data Points", number: "1M+", helpText: "Processed monthly" }
            ].map((stat, index) => (
              <StatBox key={index} {...stat} index={index} />
            ))}
          </SimpleGrid>
        </Container>
      </Box>

      {/* How It Works Section */}
      <Container maxW="container.xl" py={16}>
        <VStack spacing={12}>
          <Heading size="xl" textAlign="center">Multiple Tools, One Platform</Heading>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
            <StepCard
              number="1"
              title="Set Up Your Tools"
              description="Create forms with our builder and integrate logging with our SDK"
            />
            <StepCard
              number="2"
              title="Connect Telegram"
              description="Link your Telegram account once for all features"
            />
            <StepCard
              number="3"
              title="Start Receiving"
              description="Get form responses, custom logs and uptime alerts instantly in Telegram"
            />
          </SimpleGrid>
        </VStack>
      </Container>

      {/* Features Section */}
      <Container maxW="container.xl" py={16}>
        <VStack spacing={12}>
          <Heading size="xl">Powerful Features</Heading>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={10}>
            <FeatureCard
              icon={FiSend}
              title="Forms & Logs United"
              description="Collect user feedback and monitor system events in one place. Everything delivered instantly to Telegram."
            />
            <FeatureCard
              icon={FiBox}
              title="Dual Power"
              description="Beautiful forms for users, powerful logging for developers. Perfect harmony for modern applications."
            />
            <FeatureCard
              icon={FiLayers}
              title="Developer Friendly"
              description="Full API access for all features. Customize and integrate exactly how you need."
            />
            <FeatureCard
              icon={FiShield}
              title="Uptime Monitoring"
              description="Automatic error tracking and analysis with AI-powered insights. Keep your applications running smoothly."
            />
          </SimpleGrid>
        </VStack>
      </Container>

      {/* Use Cases Section */}
      <Box bg={useColorModeValue('gray.50', 'gray.900')} py={20}>
        <Container maxW="container.xl">
          <VStack spacing={12}>
            <Heading size="xl" textAlign="center">Perfect For</Heading>
            <Box w="full">
              <TestimonialCarousel>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={8}>
                  <UseCaseCard
                    icon={FiUsers}
                    title="Community Managers"
                    description="Collect feedback and manage member applications"
                  />
                  <UseCaseCard
                    icon={FiGlobe}
                    title="Business Owners"
                    description="Handle customer inquiries and support requests"
                  />
                  <UseCaseCard
                    icon={FiCpu}
                    title="Developers"
                    description="Monitor errors and system notifications"
                  />
                  <UseCaseCard
                    icon={FiShield}
                    title="IT Teams"
                    description="Track incidents and security alerts"
                  />
                  <UseCaseCard
                    icon={FiBriefcase}
                    title="Agencies"
                    description="Streamline client onboarding and project management"
                  />
                  <UseCaseCard
                    icon={FiCalendar}
                    title="Event Organizers"
                    description="Manage event registrations and send reminders"
                  />
                  <UseCaseCard
                    icon={FiBook}
                    title="Educators"
                    description="Collect assignments and feedback from students"
                  />
                  <UseCaseCard
                    icon={FiHeart}
                    title="Healthcare Professionals"
                    description="Streamline patient intake and appointment scheduling"
                  />
                </SimpleGrid>
              </TestimonialCarousel>
            </Box>
          </VStack>
        </Container>
      </Box>

      {/* Testimonials Section */}
      <Container maxW="container.xl" py={20}>
        <VStack spacing={12}>
          <VStack spacing={4}>
            <Heading size="xl" textAlign="center">Loved by Teams Worldwide</Heading>
            <Text fontSize="xl" color="gray.500" maxW="2xl" textAlign="center">
              See how teams are using our forms to streamline their workflows
            </Text>
          </VStack>
          <TestimonialCarousel>
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
              <TestimonialCard
                name="Alex Chen"
                role="Developer"
                company="LumbAI"
                content="The Telegram integration is brilliant. Instant notifications have cut our response time by 80%. The API access makes it perfect for our dev workflow."
              />
              <TestimonialCard
                name="Sarah Miller"
                role="Community Manager"
                company="Minak Ent."
                content="We switched from a complex form builder to this, and it's been a game-changer. The Pro plan's unlimited forms and instant notifications are worth every penny!"
              />
              <TestimonialCard
                name="Mike Johnson"
                role="IT Director"
                company="Spier Inc."
                content="Security was our main concern. The platform's robust API and instant alerts help us track and respond to incidents immediately. Excellent value for money."
              />
            </SimpleGrid>
          </TestimonialCarousel>
        </VStack>
      </Container>

      {/* Pricing Section */}
      <Box id="pricing" bg={useColorModeValue('gray.50', 'gray.900')} py={20}>
        <Container maxW="container.xl">
          <VStack spacing={12}>
            <VStack spacing={4}>
              <Heading size="xl" textAlign="center">Simple Pricing for Everyone</Heading>
              <Text fontSize="xl" color="gray.500" maxW="2xl" textAlign="center">
                Start for free, upgrade when you need more. No hidden fees, no surprises.
              </Text>
            </VStack>
            
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={{ base: 8, md: 16 }} maxW="4xl" w="full">
              <PricingCard
                title="Free"
                price="$0"
                features={[
                  'Up to 2 forms',
                  'Basic Telegram notifications',
                  'Community support',
                  'Basic form analytics',
                  '100 responses/month',
                  'API access',
                  '15 API requests daily'
                ]}
                index={0}
              />
              <PricingCard
                title="Pro"
                price="$13"
                features={[
                  'Unlimited forms',
                  'Priority notifications',
                  'Priority support',
                  'Advanced analytics',
                  'Unlimited responses',
                  'API access',
                  'Remove branding',
                  'Custom integrations',
                  'Unlimited API requests',
                  'Console and uptime monitoring'
                ]}
                isPrimary
                index={1}
              />
            </SimpleGrid>
          </VStack>
        </Container>
      </Box>

      <GuaranteeSection />

      {/* FAQ Section */}
      <Box py={20}>
        <Container maxW="container.xl">
          <VStack spacing={12} align="center">
            <FAQ />
          </VStack>
        </Container>
      </Box>

      {/* CTA Section */}
      <Container maxW="container.xl" py={20}>
        <VStack spacing={8} textAlign="center">
          <Heading size="2xl">Ready to Get Started?</Heading>
          <Text fontSize="xl" maxW="2xl">
            Join hundreds of businesses using our forms to streamline their data collection
            and instantly receive responses on Telegram.
          </Text>
          <Button
            size="lg"
            colorScheme="blue"
            rightIcon={<FiArrowRight />}
            onClick={() => navigate('/register')}
          >
            Create Your First Form
          </Button>
        </VStack>
      </Container>

      <Footer />
    </Box>
  );
}

function FeatureCard({ icon, title, description }) {
  return (
    <VStack
      align="start"
      p={8}
      bg={useColorModeValue('white', 'gray.800')}
      borderRadius="lg"
      border="1px"
      borderColor={useColorModeValue('gray.100', 'gray.700')}
      transition="all 0.2s"
      _hover={{ transform: 'translateY(-4px)', shadow: 'lg' }}
    >
      <Icon as={icon} boxSize={6} color="blue.500" />
      <Heading size="md">{title}</Heading>
      <Text color="gray.500">{description}</Text>
    </VStack>
  );
}
