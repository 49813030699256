import React, { useState } from 'react';
import {
  Box,
  Button,
  HStack,
  Text,
  Icon,
  useColorModeValue,
  useToast
} from '@chakra-ui/react';
import { FiArrowUpRight } from 'react-icons/fi';

export const UpgradeBanner = ({ onUpgrade }) => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const bgColor = useColorModeValue('blue.50', 'blue.900');
  const borderColor = useColorModeValue('blue.200', 'blue.700');

  const handleUpgrade = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('https://sruve.com/api/subscription/checkout', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }
      });
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || 'Failed to initiate upgrade');
      }
      
      window.location.href = data.checkoutUrl;
    } catch (error) {
      console.error('Upgrade error:', error);
      toast({
        title: 'Error',
        description: error.message || 'Failed to initiate upgrade. Please try again.',
        status: 'error',
        duration: 5000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      p={4}
      bg={bgColor}
      border="1px"
      borderColor={borderColor}
      borderRadius="lg"
      mb={6}
    >
      <HStack justify="space-between">
        <Text>
          Upgrade to Pro for unlimited forms, responses, and priority support!
        </Text>
        <Button
          rightIcon={<Icon as={FiArrowUpRight} />}
          colorScheme="blue"
          variant="outline"
          onClick={handleUpgrade}
          isLoading={isLoading}
        >
          Upgrade
        </Button>
      </HStack>
    </Box>
  );
}; 