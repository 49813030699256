import React from 'react';
import {
  Box, HStack, Icon, Text, VStack, useColorModeValue, Container
} from '@chakra-ui/react';
import { FiShield, FiClock, FiSmile } from 'react-icons/fi';
import MotionWrapper from './MotionWrapper';

export default function GuaranteeSection() {
  const bgColor = useColorModeValue('blue.50', 'blue.900');
  const borderColor = useColorModeValue('blue.100', 'blue.800');

  return (
    <Box py={8} bg={bgColor} borderTop="1px" borderBottom="1px" borderColor={borderColor}>
      <Container maxW="container.xl">
        <MotionWrapper>
          <HStack spacing={8} justify="center" align="center">
            <Icon as={FiShield} boxSize={8} color="green.500" />
            <VStack align="start" spacing={1}>
              <Text fontSize="xl" fontWeight="bold">
                7-Day Money Back Guarantee
              </Text>
              <Text color="gray.600">
                Try our Pro plan risk-free. Not satisfied? Get a full refund, no questions asked.
              </Text>
            </VStack>
          </HStack>
        </MotionWrapper>
      </Container>
    </Box>
  );
}
