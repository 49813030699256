import React, { useEffect } from 'react';
import { Box, IconButton, useBreakpointValue, HStack } from '@chakra-ui/react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { motion, AnimatePresence } from 'framer-motion';

const MotionBox = motion(Box);

export default function TestimonialCarousel({ children }) {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [autoPlay, setAutoPlay] = React.useState(true);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const childrenArray = React.Children.toArray(children)[0].props.children;
  const slides = React.Children.toArray(childrenArray);

  useEffect(() => {
    let interval;
    if (autoPlay) {
      interval = setInterval(() => {
        setCurrentIndex((prev) => (prev + 1) % slides.length);
      }, 5000); // Change slide every 5 seconds
    }
    return () => clearInterval(interval);
  }, [autoPlay, slides.length]);

  const nextSlide = () => {
    setAutoPlay(false);
    setCurrentIndex((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setAutoPlay(false);
    setCurrentIndex((prev) => (prev - 1 + slides.length) % slides.length);
  };

  if (!isMobile) {
    return children;
  }

  return (
    <Box position="relative" width="full" overflow="hidden">
      <AnimatePresence initial={false} mode="wait">
        <MotionBox
          key={currentIndex}
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -100 }}
          transition={{ 
            type: "spring",
            stiffness: 300,
            damping: 30
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={0.3}
          onDragEnd={(e, { offset, velocity }) => {
            setAutoPlay(false);
            const swipe = Math.abs(offset.x) * velocity.x;
            if (swipe < -50) {
              nextSlide();
            } else if (swipe > 50) {
              prevSlide();
            }
          }}
          px={4}
          width="100%"
        >
          {slides[currentIndex]}
        </MotionBox>
      </AnimatePresence>

      <HStack 
        justify="center" 
        mt={6} 
        spacing={2}
        position="relative"
        zIndex={2}
      >
        {slides.map((_, index) => (
          <Box
            key={index}
            h="3px"
            w={currentIndex === index ? "24px" : "12px"}
            bg={currentIndex === index ? "blue.500" : "gray.200"}
            transition="all 0.2s"
            borderRadius="full"
            cursor="pointer"
            onClick={() => {
              setAutoPlay(false);
              setCurrentIndex(index);
            }}
          />
        ))}
      </HStack>

      <IconButton
        aria-label="Previous"
        icon={<FiChevronLeft />}
        position="absolute"
        left={2}
        top="50%"
        transform="translateY(-50%)"
        onClick={prevSlide}
        size="md"
        variant="solid"
        bg="white"
        shadow="lg"
        color="gray.800"
        _hover={{ bg: 'gray.100' }}
        zIndex={2}
      />
      <IconButton
        aria-label="Next"
        icon={<FiChevronRight />}
        position="absolute"
        right={2}
        top="50%"
        transform="translateY(-50%)"
        onClick={nextSlide}
        size="md"
        variant="solid"
        bg="white"
        shadow="lg"
        color="gray.800"
        _hover={{ bg: 'gray.100' }}
        zIndex={2}
      />
    </Box>
  );
}
