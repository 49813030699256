import React from 'react';
import { VStack } from '@chakra-ui/react';
import TextInput from './inputs/TextInput';
import SelectInput from './inputs/SelectInput';
import RadioInput from './inputs/RadioInput';
import CheckboxInput from './inputs/CheckboxInput';
import TextareaInput from './inputs/TextareaInput';
import DateInput from './inputs/DateInput';
import FileInput from './inputs/FileInput';
import RatingInput from './inputs/RatingInput';

const componentMap = {
  text: TextInput,
  email: TextInput,
  number: TextInput,
  tel: TextInput,
  password: TextInput,
  textarea: TextareaInput,
  select: SelectInput,
  radio: RadioInput,
  checkbox: CheckboxInput,
  date: DateInput,
  file: FileInput,
  rating: RatingInput
};

export default function FormRenderer({ 
  components = [], 
  onChange, 
  values = {},
  errors = {} 
}) {
  return (
    <VStack 
      spacing={6} 
      width="100%"
      bg="white"
      p={6}
      borderRadius="lg"
      boxShadow="sm"
      _dark={{
        bg: 'gray.800'
      }}
    >
      {components.map(component => {
        const Component = componentMap[component.type];
        if (!Component) return null;
        
        return (
          <Component
            key={component.id}
            field={component}
            onChange={onChange}
            value={values[component.id]}
            error={errors[component.id]}
          />
        );
      })}
    </VStack>
  );
} 