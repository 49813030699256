import React from 'react';
import {
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
} from '@chakra-ui/react';

export default function BaseInput({
  label,
  error,
  helperText,
  isRequired,
  children,
  mb = 4
}) {
  return (
    <FormControl 
      isRequired={isRequired} 
      isInvalid={!!error}
      mb={mb}
    >
      <FormLabel>{label}</FormLabel>
      {children}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
}