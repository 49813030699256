import React from 'react';
import { Textarea } from '@chakra-ui/react';
import BaseInput from './BaseInput';

export default function TextareaInput({ field, onChange, value, error }) {
  return (
    <BaseInput field={field} error={error}>
      <Textarea
        value={value || ''}
        onChange={(e) => onChange(field.id, e.target.value)}
        placeholder={field.placeholder}
        size="md"
        borderRadius="md"
        borderWidth="1px"
        minHeight="120px"
        _hover={{
          borderColor: 'blue.400',
        }}
        _focus={{
          borderColor: 'blue.500',
          boxShadow: '0 0 0 1px var(--chakra-colors-blue-500)',
        }}
      />
    </BaseInput>
  );
} 