import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import PrivateRoute from './components/PrivateRoute';
import FormBuilder from './components/FormBuilder/FormBuilder';
import FormManagement from './components/FormBuilder/FormManagement';
import FormView from './components/FormView';
import ThankYou from './components/ThankYou';
import ProfileSettings from './components/Dashboard/ProfileSettings';
import Landing from './Landing/Landing';
import Status from './pages/Status';
import NotFound from './pages/Notfound';

function AppRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/forms"
        element={
          <PrivateRoute>
            <FormManagement />
          </PrivateRoute>
        }
      />
      <Route
        path="/forms/create"
        element={
          <PrivateRoute>
            <FormBuilder />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/profile"
        element={
          <PrivateRoute>
            <ProfileSettings />
          </PrivateRoute>
        }
      />
      <Route path="/f/:slug" element={<FormView />} />
      <Route path="/thank-you" element={<ThankYou />} />
      <Route path="/status" element={<Status />} />
      <Route path="/404" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
      <Route path="/" element={<Landing />} />
    </Routes>
  );
}

export default AppRoutes;