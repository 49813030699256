import React from 'react';
import {
  Box,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  useColorModeValue
} from '@chakra-ui/react';
import MotionWrapper from './MotionWrapper';

export default function StatBox({ label, number, helpText, index }) {
  return (
    <MotionWrapper delay={index * 0.1}>
      <Box
        p={6}
        bg={useColorModeValue('white', 'gray.800')}
        borderRadius="lg"
        borderWidth="1px"
        borderColor={useColorModeValue('gray.100', 'gray.700')}
        shadow="sm"
      >
        <Stat>
          <StatLabel fontSize="sm" fontWeight="medium">{label}</StatLabel>
          <StatNumber fontSize="3xl" fontWeight="bold">{number}</StatNumber>
          <StatHelpText>{helpText}</StatHelpText>
        </Stat>
      </Box>
    </MotionWrapper>
  );
}
