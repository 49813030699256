import React from 'react';
import {
  VStack,
  Text,
  Avatar,
  Box,
  HStack,
  useColorModeValue
} from '@chakra-ui/react';

export default function TestimonialCard({ name, role, company, content }) {
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.100', 'gray.700');

  return (
    <VStack
      p={{ base: 4, md: 8 }}
      bg={bgColor}
      borderRadius="lg"
      borderWidth="1px"
      borderColor={borderColor}
      spacing={{ base: 3, md: 6 }}
      align="start"
      w="full"
      minH={{ base: "auto", md: "300px" }}
      justify="space-between"
    >
      <Text 
        color="gray.500" 
        fontSize={{ base: "sm", md: "lg" }}
        lineHeight="tall"
        px={{ base: 6, md: 0 }}
      >
        {content}
      </Text>
      
      <HStack spacing={3} px={{ base: 6, md: 0 }}>
        <Avatar name={name} size="sm" />
        <Box>
          <Text fontWeight="medium" fontSize={{ base: "xs", md: "md" }}>
            {name}
          </Text>
          <Text fontSize={{ base: "2xs", md: "sm" }} color="gray.500">
            {role} at {company}
          </Text>
        </Box>
      </HStack>
    </VStack>
  );
}
