import React from 'react';
import {
  Button,
  Input,
  Text,
  VStack,
  useColorModeValue
} from '@chakra-ui/react';
import { FiUpload } from 'react-icons/fi';
import BaseInput from './BaseInput';

export default function FileInput({ field, onChange, value, error }) {
  const inputRef = React.useRef();
  const buttonBg = useColorModeValue('gray.100', 'gray.700');
  const buttonHoverBg = useColorModeValue('gray.200', 'gray.600');

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      onChange(field.id, file);
    }
  };

  return (
    <BaseInput field={field} error={error}>
      <VStack align="stretch" spacing={2}>
        <Input
          type="file"
          ref={inputRef}
          onChange={handleFileChange}
          accept={field.properties?.accept}
          hidden
        />
        <Button
          leftIcon={<FiUpload />}
          onClick={() => inputRef.current?.click()}
          bg={buttonBg}
          _hover={{ bg: buttonHoverBg }}
          width="100%"
        >
          Choose File
        </Button>
        {value && (
          <Text fontSize="sm" color="gray.500">
            Selected: {value.name}
          </Text>
        )}
      </VStack>
    </BaseInput>
  );
} 