import React from 'react';
import { Input } from '@chakra-ui/react';
import BaseInput from './BaseInput';

export default function TextInput({ field, onChange, value, error }) {
  return (
    <BaseInput
      label={field.label}
      error={error}
      helperText={field.helperText}
      isRequired={field.required}
    >
      <Input
        type={field.type}
        value={value || ''}
        onChange={(e) => onChange(field.id, e.target.value)}
        placeholder={field.placeholder}
        _focus={{
          borderColor: 'blue.400',
          boxShadow: '0 0 0 1px var(--chakra-colors-blue-400)'
        }}
      />
    </BaseInput>
  );
} 