import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure
} from '@chakra-ui/react';
import { FiEye } from 'react-icons/fi';
import FormRenderer from './FormRenderer';

export default function FormPreview({ formData }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Map the form fields to the correct structure
  const components = formData.fields.map(field => ({
    id: field.id,
    type: field.type,
    label: field.label,
    placeholder: field.placeholder,
    required: field.required,
    options: field.options,
    width: '100%',
    styling: {
      marginBottom: '1rem'
    }
  }));

  return (
    <>
      <Button leftIcon={<FiEye />} onClick={onOpen} variant="ghost">
        Preview Form
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{formData.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormRenderer 
              title={formData.title}
              description={formData.description}
              components={components}
              onChange={() => {}}
              preview={true}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
} 