import React from 'react';
import {
  VStack,
  Heading,
  Text,
  Button,
  List,
  ListItem,
  ListIcon,
  useColorModeValue,
  Badge,
  HStack,
  Box,
  SimpleGrid
} from '@chakra-ui/react';
import { FiCheck } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import MotionWrapper from './MotionWrapper';

export default function PricingCard({ title, price, features, isPrimary = false, index }) {
  const navigate = useNavigate();
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.100', 'gray.700');
  const popularBadge = isPrimary ? (
    <Badge
      colorScheme="blue"
      position="absolute"
      top="-4"
      right="50%"
      transform="translateX(50%)"
      px={4}
      py={1}
      borderRadius="full"
      fontSize="sm"
      textTransform="uppercase"
      letterSpacing="wider"
    >
      Most Popular
    </Badge>
  ) : null;

  return (
    <MotionWrapper delay={index * 0.2}>
      <VStack
        p={{ base: 6, md: 8 }}
        pb={{ base: 8, md: isPrimary ? 12 : 8 }}
        spacing={{ base: 4, md: 6 }}
        transform={{ base: 'scale(1)', md: isPrimary ? 'scale(1.05)' : 'scale(1)' }}
        mx={{ base: 4, md: 0 }}
        bg={isPrimary ? 'blue.500' : bgColor}
        color={isPrimary ? 'white' : 'inherit'}
        borderRadius="xl"
        borderWidth="2px"
        borderColor={isPrimary ? 'white' : borderColor}
        align="stretch"
        position="relative"
        shadow={isPrimary ? '2xl' : 'lg'}
        _hover={{
          transform: isPrimary ? 'scale(1.08)' : 'scale(1.03)',
          transition: 'transform 0.2s'
        }}
      >
        {popularBadge}
        <VStack spacing={2} align="stretch">
          <Text fontSize="xl" fontWeight="medium">{title}</Text>
          <HStack align="baseline">
            <Heading size="3xl">{price}</Heading>
            <Text fontSize="lg">/month</Text>
          </HStack>
        </VStack>

        <List spacing={4}>
          {features.map((feature, idx) => (
            <ListItem key={idx} display="flex" alignItems="center">
              <ListIcon as={FiCheck} color={isPrimary ? 'white' : 'blue.500'} boxSize={5} />
              <Text>{feature}</Text>
            </ListItem>
          ))}
        </List>

        <Button
          colorScheme={isPrimary ? 'white' : 'blue'}
          variant={isPrimary ? 'solid' : 'outline'}
          size="lg"
          onClick={() => navigate('/register')}
          borderWidth={isPrimary ? '1px' : undefined}
          borderColor={isPrimary ? 'white' : undefined}
          _hover={{
            transform: 'translateY(-2px)',
            shadow: 'lg'
          }}
        >
          {isPrimary ? 'Get Started Now' : 'Start Free'}
        </Button>
      </VStack>
    </MotionWrapper>
  );
}
