import React, { useState } from 'react';
import {
  Container,
  VStack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  IconButton,
  Heading,
  Box,
  Text,
  Divider,
  useToast,
  Alert,
  AlertIcon,
  Switch,
  Textarea,
  FormHelperText,
  useColorModeValue
} from '@chakra-ui/react';
import { FiPlus, FiTrash2, FiSave, FiMove } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import FormPreview from './FormPreview';
import { generateFormComponent } from '../../utils/formGenerator';
import { useFormSubmission } from '../../hooks/useFormSubmission';
import { INPUT_TYPES } from '../../config/inputTypes';

export default function FormBuilder({ initialData = null, isEditing = false }) {
  const { user } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();

  const [formData, setFormData] = useState(initialData || {
    title: '',
    description: '',
    fields: [],
    settings: {
      telegramNotifications: true,
      active: true
    }
  });

  const { submitForm, isSubmitting } = useFormSubmission();

  const addField = () => {
    setFormData(prev => ({
      ...prev,
      fields: [...prev.fields, {
        id: Date.now(),
        type: 'text',
        label: '',
        required: false,
        options: [],
        placeholder: '',
        helperText: ''
      }]
    }));
  };

  const removeField = (fieldId) => {
    setFormData(prev => ({
      ...prev,
      fields: prev.fields.filter(field => field.id !== fieldId)
    }));
  };

  const updateField = (id, updates) => {
    setFormData(prev => ({
      ...prev,
      fields: prev.fields.map(field => {
        if (field.id === id) {
          const newField = { ...field, ...updates };
          
          // Initialize options array when switching to a field type that needs options
          if (updates.type && ['select', 'radio', 'checkbox'].includes(updates.type)) {
            newField.options = newField.options || ['Option 1', 'Option 2'];
          }
          
          return newField;
        }
        return field;
      })
    }));
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    // Only proceed if we're dropping in our droppable area
    if (destination.droppableId === 'form-fields-list') {
      const newFields = Array.from(formData.fields);
      const [draggedItem] = newFields.splice(source.index, 1);
      newFields.splice(destination.index, 0, draggedItem);

      setFormData(prevData => ({
        ...prevData,
        fields: newFields
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await submitForm(formData);
      navigate('/forms');
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message || 'Failed to create form',
        status: 'error',
        duration: 3000,
      });
    }
  };

  const bgColor = useColorModeValue('white', 'gray.800');
  const fieldBorderColor = useColorModeValue('gray.200', 'gray.600');

  if (!user?.telegramId) {
    return (
      <Container maxW="container.md" py={8}>
        <Alert status="warning">
          <AlertIcon />
          <Text>Please connect your Telegram account to create forms</Text>
        </Alert>
      </Container>
    );
  }

  return (
    <Container maxW="container.md" py={8}>
      <VStack spacing={8} as="form" onSubmit={handleSubmit}>
        <Heading size="lg">{isEditing ? 'Edit Form' : 'Create Form'}</Heading>
        
        <FormControl isRequired>
          <FormLabel>Form Title</FormLabel>
          <Input
            value={formData.title}
            onChange={(e) => setFormData(prev => ({
              ...prev,
              title: e.target.value
            }))}
            placeholder="Enter form title"
          />
        </FormControl>

        <FormControl>
          <FormLabel>Description</FormLabel>
          <Textarea
            value={formData.description}
            onChange={(e) => setFormData(prev => ({
              ...prev,
              description: e.target.value
            }))}
            placeholder="Enter form description"
          />
        </FormControl>

        <Divider />

        <Box width="100%">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="form-fields-list">
              {(provided) => (
                <VStack
                  spacing={4}
                  width="100%"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {formData.fields.map((field, index) => (
                    <Draggable
                      key={field.id}
                      draggableId={field.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          width="100%"
                          opacity={snapshot.isDragging ? 0.5 : 1}
                        >
                          <HStack
                            p={4}
                            borderWidth={1}
                            borderRadius="md"
                            borderColor={fieldBorderColor}
                            bg={bgColor}
                            width="100%"
                            {...provided.dragHandleProps}
                          >
                            {/* <IconButton
                              icon={<FiMove />}
                              variant="ghost"
                              aria-label="Move field"
                            /> */}
                            <IconButton
                              icon={<FiTrash2 />}
                              onClick={() => removeField(field.id)}
                              colorScheme="red"
                              variant="ghost"
                              aria-label="Remove field"
                            />

                            <FormControl>
                              <FormLabel>Field Type</FormLabel>
                              <Select
                                value={field.type}
                                onChange={(e) => updateField(field.id, { type: e.target.value })}
                              >
                                {INPUT_TYPES.map(type => (
                                  <option key={type.value} value={type.value}>
                                    {type.label}
                                  </option>
                                ))}
                              </Select>
                            </FormControl>

                            <FormControl>
                              <FormLabel>Label</FormLabel>
                              <Input
                                value={field.label}
                                onChange={(e) => updateField(field.id, { label: e.target.value })}
                                placeholder="Enter field label"
                              />
                            </FormControl>

                            <FormControl>
                              <FormLabel>Placeholder</FormLabel>
                              <Input
                                value={field.placeholder}
                                onChange={(e) => updateField(field.id, { placeholder: e.target.value })}
                                placeholder="Enter placeholder text"
                              />
                            </FormControl>

                            {['select', 'radio', 'checkbox'].includes(field.type) && (
                              <FormControl>
                                <FormLabel>Options</FormLabel>
                                <VStack spacing={2}>
                                  {(field.options || []).map((option, optionIndex) => (
                                    <HStack key={optionIndex}>
                                      <Input
                                        value={option}
                                        onChange={(e) => {
                                          const newOptions = [...(field.options || [])];
                                          newOptions[optionIndex] = e.target.value;
                                          updateField(field.id, { options: newOptions });
                                        }}
                                        placeholder={`Option ${optionIndex + 1}`}
                                      />
                                      <IconButton
                                        icon={<FiTrash2 />}
                                        onClick={() => {
                                          const newOptions = (field.options || []).filter((_, i) => i !== optionIndex);
                                          updateField(field.id, { options: newOptions });
                                        }}
                                        colorScheme="red"
                                        variant="ghost"
                                      />
                                    </HStack>
                                  ))}
                                  <Button
                                    leftIcon={<FiPlus />}
                                    onClick={() => {
                                      const newOptions = [...(field.options || []), `Option ${(field.options || []).length + 1}`];
                                      updateField(field.id, { options: newOptions });
                                    }}
                                    size="sm"
                                    variant="outline"
                                  >
                                    Add Option
                                  </Button>
                                </VStack>
                              </FormControl>
                            )}

                            <FormControl>
                              <FormLabel>Required</FormLabel>
                              <Switch
                                isChecked={field.required}
                                onChange={(e) => updateField(field.id, {
                                  required: e.target.checked
                                })}
                              />
                            </FormControl>
                          </HStack>
                        </Box>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </VStack>
              )}
            </Droppable>
          </DragDropContext>
        </Box>

        <Button
          leftIcon={<FiPlus />}
          onClick={addField}
          width="100%"
          colorScheme="blue"
          variant="outline"
        >
          Add Field
        </Button>

        <Button
          type="submit"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={formData.fields.length === 0}
        >
          {isEditing ? 'Save Changes' : 'Create Form'}
        </Button>
      </VStack>
    </Container>
  );
} 