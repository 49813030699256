import React, { useEffect } from 'react';
import {
  Box,
  Container,
  HStack,
  Button,
  useColorModeValue,
  Link as ChakraLink,
  Stack,
  IconButton,
  Collapse,
  Divider,
  VStack,
  useDisclosure
} from '@chakra-ui/react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FiMenu, FiX } from 'react-icons/fi';

export default function Navigation() {
  const location = useLocation();
  const { isOpen, onToggle } = useDisclosure();
  const navigate = useNavigate();
  
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.100', 'gray.700');
  const hoverBgColor = useColorModeValue('gray.50', 'gray.700');
  const hoverColor = useColorModeValue('blue.500', 'blue.200');

  const navItems = [
    { label: 'Pricing', href: '/#pricing' },
    { label: 'Status', href: '/status' }
  ];

  const scrollToPricing = () => {
    if (location.pathname !== '/') {
      navigate('/#pricing');
      return;
    }
    
    const pricingSection = document.getElementById('pricing');
    if (pricingSection) {
      const headerOffset = 80;
      const elementPosition = pricingSection.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
    if (isOpen) onToggle();
  };

  useEffect(() => {
    if (location.hash === '#pricing') {
      setTimeout(scrollToPricing, 100);
    }
  }, [location]);

  const handleNavigation = (href) => {
    if (href === '/#pricing') {
      scrollToPricing();
    } else {
      navigate(href);
    }
    if (isOpen) onToggle();
  };

  return (
    <Box position="sticky" top={0} zIndex={10} bg={bgColor} borderBottom="1px" borderColor={borderColor}>
      <Container maxW="container.xl" py={4}>
        <Stack direction="row" justify="space-between" align="center">
          <ChakraLink as={Link} to="/" fontSize="xl" fontWeight="bold">
            Sruve
          </ChakraLink>

          <HStack spacing={8} display={{ base: 'none', md: 'flex' }}>
            {navItems.map(item => (
              <ChakraLink 
                key={item.label} 
                as={Link} 
                to={item.href}
                _hover={{ color: hoverColor }}
              >
                {item.label}
              </ChakraLink>
            ))}
            <Button variant="ghost" onClick={() => handleNavigation('/login')}>
              Log In
            </Button>
            <Button colorScheme="blue" onClick={() => handleNavigation('/register')}>
              Sign Up
            </Button>
          </HStack>

          <IconButton
            display={{ base: 'flex', md: 'none' }}
            icon={isOpen ? <FiX /> : <FiMenu />}
            variant="ghost"
            onClick={onToggle}
            aria-label="Toggle Navigation"
          />
        </Stack>

        <Collapse in={isOpen}>
          <VStack spacing={4} mt={4} display={{ base: 'flex', md: 'none' }}>
            {navItems.map(item => (
              <ChakraLink 
                key={item.label} 
                as={Link} 
                to={item.href}
                w="full" 
                textAlign="center" 
                py={2}
                _hover={{ bg: hoverBgColor }}
                borderRadius="md"
                transition="all 0.2s"
                onClick={onToggle}
              >
                {item.label}
              </ChakraLink>
            ))}
            <Divider />
            <Button 
              variant="ghost" 
              w="full" 
              onClick={() => handleNavigation('/login')}
            >
              Log In
            </Button>
            <Button 
              colorScheme="blue" 
              w="full" 
              onClick={() => handleNavigation('/register')}
            >
              Sign Up
            </Button>
          </VStack>
        </Collapse>
      </Container>
    </Box>
  );
}
