import { 
  FiType, FiMail, FiHash, FiAlignLeft, FiList, 
  FiCheckCircle, FiCalendar, FiFile, FiStar 
} from 'react-icons/fi';

export const INPUT_TYPES = [
  { 
    value: 'text', 
    label: 'Text Input',
    icon: FiType,
    hasOptions: false 
  },
  { 
    value: 'email', 
    label: 'Email',
    icon: FiMail,
    hasOptions: false 
  },
  { 
    value: 'number', 
    label: 'Number',
    icon: FiHash,
    hasOptions: false 
  },
  { 
    value: 'textarea', 
    label: 'Text Area',
    icon: FiAlignLeft,
    hasOptions: false 
  },
  { 
    value: 'select', 
    label: 'Dropdown',
    icon: FiList,
    hasOptions: true,
    defaultOptions: ['Option 1', 'Option 2', 'Option 3']
  },
  { 
    value: 'radio', 
    label: 'Radio Group',
    icon: FiCheckCircle,
    hasOptions: true,
    defaultOptions: ['Choice 1', 'Choice 2', 'Choice 3']
  },
  { 
    value: 'date', 
    label: 'Date Input',
    icon: FiCalendar,
    hasOptions: false 
  },
  { 
    value: 'rating', 
    label: 'Rating',
    icon: FiStar,
    hasOptions: false 
  }
]; 