import React from 'react';
import {
  VStack, Heading, Accordion, AccordionItem,
  AccordionButton, AccordionPanel, AccordionIcon,
  Box, Text, useColorModeValue
} from '@chakra-ui/react';
import MotionWrapper from './MotionWrapper';

const faqData = [
  {
    question: "How do Telegram notifications work?",
    answer: "Once you connect your Telegram account, you'll receive instant notifications whenever someone submits your form. It's as simple as chatting with a friend!"
  },
  {
    question: "Can I try the Pro features before paying?",
    answer: "Yes! Start with our free plan and upgrade when you need more. Plus, our Pro plan comes with a 7-day money-back guarantee."
  },
  {
    question: "What happens if I exceed the free plan limits?",
    answer: "We'll notify you when you're approaching the limits. Your forms will continue to work, but you'll need to upgrade to receive new responses."
  },
  {
    question: "How does the API access work?",
    answer: "You get a unique API key to integrate logging within your applications. Pro users get unlimited API requests and priority support."
  },
  {
    question: "Can I export my form responses?",
    answer: "All forms are logged and sent to your telegram in milliseconds of entries being made."
  }
];

export default function FAQ() {
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <MotionWrapper>
      <VStack spacing={8} w="full" maxW="4xl">
        <Heading size="xl">Frequently Asked Questions</Heading>
        <Accordion allowMultiple w="full">
          {faqData.map((item, index) => (
            <AccordionItem key={index} border="1px" borderColor={borderColor} borderRadius="md" my={2}>
              <AccordionButton py={4}>
                <Box flex="1" textAlign="left" fontWeight="medium">
                  {item.question}
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Text color="gray.500">{item.answer}</Text>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </VStack>
    </MotionWrapper>
  );
}
