import React from 'react';
import {
  Box,
  Container,
  VStack,
  Heading,
  Text,
  Button,
  SimpleGrid,
  useColorModeValue,
  Icon,
  HStack
} from '@chakra-ui/react';
import { FiCheck, FiArrowLeft } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Footer from '../components/Footer';

const MotionBox = motion(Box);

export default function Status() {
  const navigate = useNavigate();
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.100', 'gray.700');

  const systems = [
    { name: 'Form Service', status: 'Operational' },
    { name: 'Logging API', status: 'Operational' },
    { name: 'Telegram Bot', status: 'Operational' },
    { name: 'Dashboard', status: 'Operational' }
  ];

  return (
    <Box minH="100vh" bg={useColorModeValue('gray.50', 'gray.900')}>
      <Container maxW="container.xl" py={20}>
        <VStack spacing={12} align="stretch">
          <Button
            leftIcon={<FiArrowLeft />}
            variant="ghost"
            w="fit-content"
            onClick={() => navigate('/')}
          >
            Back to Home
          </Button>

          <VStack spacing={4} align="center">
            <Heading size="2xl">All Systems Operational</Heading>
            <Text fontSize="xl" color="gray.500">
              Our services are running smoothly and ready to serve you
            </Text>
          </VStack>

          <MotionBox
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
              {systems.map((system, index) => (
                <MotionBox
                  key={system.name}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <Box
                    p={6}
                    bg={bgColor}
                    borderRadius="lg"
                    borderWidth="1px"
                    borderColor={borderColor}
                  >
                    <HStack justify="space-between">
                      <Text fontSize="lg" fontWeight="medium">{system.name}</Text>
                      <HStack color="green.500">
                        <Icon as={FiCheck} />
                        <Text>{system.status}</Text>
                      </HStack>
                    </HStack>
                  </Box>
                </MotionBox>
              ))}
            </SimpleGrid>
          </MotionBox>
          <Footer />
        </VStack>
      </Container>
    </Box>
  );
}
