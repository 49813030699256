import React from 'react';
import { RadioGroup, Radio, Stack, useColorModeValue } from '@chakra-ui/react';
import BaseInput from './BaseInput';

export default function RadioInput({ field, onChange, value, error }) {
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const hoverBg = useColorModeValue('gray.50', 'gray.700');

  return (
    <BaseInput
      label={field.label}
      error={error}
      helperText={field.helperText}
      isRequired={field.required}
    >
      <RadioGroup
        value={value || ''}
        onChange={(val) => onChange(field.id, val)}
      >
        <Stack spacing={3}>
          {(field.options || []).map((option) => (
            <Radio
              key={option}
              value={option}
              borderColor={borderColor}
              _hover={{ bg: hoverBg }}
            >
              {option}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
    </BaseInput>
  );
} 