import React, { useState, useEffect } from 'react';
import {
  VStack,
  Button,
  useToast,
  Heading,
  Text,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

// Import input components
import TextInput from '../FormBuilder/inputs/TextInput';
import SelectInput from '../FormBuilder/inputs/SelectInput';
import RadioInput from '../FormBuilder/inputs/RadioInput';
import CheckboxInput from '../FormBuilder/inputs/CheckboxInput';
import TextareaInput from '../FormBuilder/inputs/TextareaInput';
import DateInput from '../FormBuilder/inputs/DateInput';
import FileInput from '../FormBuilder/inputs/FileInput';
import RatingInput from '../FormBuilder/inputs/RatingInput';

const componentMap = {
  text: TextInput,
  email: TextInput,
  number: TextInput,
  tel: TextInput,
  password: TextInput,
  textarea: TextareaInput,
  select: SelectInput,
  radio: RadioInput,
  checkbox: CheckboxInput,
  date: DateInput,
  file: FileInput,
  rating: RatingInput
};

export default function FormRenderer({ formId, formConfig: initialFormConfig, preview = false }) {
  const navigate = useNavigate();
  const toast = useToast();
  const [formConfig, setFormConfig] = useState(initialFormConfig);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (initialFormConfig) {
      console.log('Setting form config:', initialFormConfig);
      setFormConfig(initialFormConfig);
      initializeFormData(initialFormConfig.fields);
    }
  }, [initialFormConfig]);

  const initializeFormData = (fields) => {
    if (!fields) return;
    const initialData = {};
    fields.forEach(field => {
      initialData[field.label] = field.type === 'checkbox' ? [] : '';
    });
    setFormData(initialData);
  };

  const handleFieldChange = (fieldId, value) => {
    // Find the field label using the fieldId
    const field = formConfig.fields.find(f => f.id === fieldId);
    if (!field?.label) return;

    console.log('Field change:', field.label, value);
    setFormData(prev => ({
      ...prev,
      [field.label]: value
    }));
    if (errors[fieldId]) {
      setErrors(prev => ({ ...prev, [fieldId]: null }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (preview || isSubmitting) return;
    
    setIsSubmitting(true);
    try {
      const response = await fetch(`https://sruve.com/api/forms/${formId}/submit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.error || 'Failed to submit form');
      }

      // toast({
      //   title: 'Success',
      //   description: 'Form submitted successfully',
      //   status: 'success',
      //   duration: 3000
      // });

      navigate('/thank-you');
    } catch (error) {
      console.error('Form submission error:', error);
      toast({
        title: 'Error',
        description: error.message || 'An error occurred while submitting the form',
        status: 'error',
        duration: 3000
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!formConfig) {
    console.log('No form config available');
    return null;
  }

  console.log('Rendering form with fields:', formConfig.fields);

  return (
    <VStack 
      spacing={8} 
      as="form" 
      onSubmit={handleSubmit}
      width="100%"
      bg="white"
      p={6}
      borderRadius="lg"
      boxShadow="sm"
      _dark={{
        bg: 'gray.800'
      }}
    >
      <Heading size="lg">{formConfig.title}</Heading>
      {formConfig.description && (
        <Text>{formConfig.description}</Text>
      )}

      {formConfig.fields?.map(field => {
        const Component = componentMap[field.type];
        if (!Component) {
          console.warn(`No component found for field type: ${field.type}`);
          return null;
        }

        return (
          <Component
            key={field.id}
            field={field}
            onChange={handleFieldChange}
            value={formData[field.label]}
            error={errors[field.id]}
          />
        );
      })}

      {!preview && (
        <Button
          type="submit"
          colorScheme="blue"
          size="lg"
          width="100%"
          isLoading={isSubmitting}
          loadingText="Submitting..."
        >
          Submit
        </Button>
      )}
    </VStack>
  );
} 