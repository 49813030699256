import React from 'react';
import {
  VStack,
  Icon,
  Heading,
  Text,
  useColorModeValue
} from '@chakra-ui/react';

export default function UseCaseCard({ icon, title, description }) {
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.100', 'gray.700');

  return (
    <VStack
      p={8}
      bg={bgColor}
      borderRadius="lg"
      borderWidth="1px"
      borderColor={borderColor}
      spacing={4}
      align="start"
      transition="all 0.2s"
      _hover={{ transform: 'translateY(-4px)', shadow: 'md' }}
    >
      <Icon as={icon} boxSize={6} color="blue.500" />
      <Heading size="md">{title}</Heading>
      <Text color="gray.500">{description}</Text>
    </VStack>
  );
}
