import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { useAuth } from '../contexts/AuthContext';

export function useFormSubmission() {
  const navigate = useNavigate();
  const toast = useToast();
  const { user } = useAuth();
  const { token } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitForm = async (formData) => {
    setIsSubmitting(true);
    
    if (!user?.telegramId) {
      toast({
        title: 'Error',
        description: 'Please connect your Telegram account first',
        status: 'error',
        duration: 3000
      });
      setIsSubmitting(false);
      return;
    }

    try {
      const formPayload = {
        title: formData.title,
        description: formData.description,
        fields: formData.fields.map(field => ({
          id: field.id,
          type: field.type,
          label: field.label,
          placeholder: field.placeholder,
          required: field.required,
          options: field.options,
          properties: field.properties || {},
          validation: field.validation || {}
        })),
        settings: {
          telegramNotifications: formData.settings?.telegramNotifications || false,
          active: true,
          theme: {
            colors: {
              primary: '#3182CE',
              background: '#FFFFFF',
              text: '#1A202C'
            },
            spacing: {
              padding: '2rem',
              borderRadius: '0.5rem'
            }
          }
        },
        apikey: user.apiKey
      };

      const response = await fetch('https://sruve.com/api/forms', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(formPayload)
      });

      let responseData;
      try {
        responseData = await response.json();
      } catch (parseError) {
        console.error('Failed to parse response:', parseError);
        throw new Error('Invalid server response');
      }

      if (!response.ok) {
        throw new Error(responseData.error || 'Failed to create form');
      }
      
      toast({
        title: 'Success',
        description: `Form created! Share using: https://forms.sruve.com/f/${responseData.formId}`,
        status: 'success',
        duration: 5000
      });
      
      navigate('/forms');
      return responseData.formId;
    } catch (error) {
      console.error('Form submission error:', error);
      toast({
        title: 'Error creating form',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return { submitForm, isSubmitting };
} 