import React, { useEffect, useState } from 'react';
import {
  Container,
  VStack,
  HStack,
  Button,
  Text,
  Heading,
  SimpleGrid,
  Box,
  Badge,
  IconButton,
  useToast,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Spinner
} from '@chakra-ui/react';
import { FiMoreVertical, FiTrash2, FiLink, FiToggleLeft, FiToggleRight, FiPlus, FiArrowLeft } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

export default function FormManagement() {
  const navigate = useNavigate();
  const toast = useToast();
  const { user, token } = useAuth();
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(true);
  
  const bgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  const deleteForm = async (formId) => {
    try {
      const response = await fetch(`https://sruve.com/api/forms/${formId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) throw new Error('Failed to delete form');

      setForms(prev => prev.filter(form => form.formId !== formId));
      toast({
        title: 'Success',
        description: 'Form deleted successfully',
        status: 'success',
        duration: 3000
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 3000
      });
    }
  };

  const toggleFormStatus = async (formId, currentStatus) => {
    try {
      const response = await fetch(`https://sruve.com/api/forms/${formId}/toggle`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ active: !currentStatus })
      });

      if (!response.ok) throw new Error('Failed to update form status');

      setForms(prev => prev.map(form => 
        form.formId === formId ? { ...form, settings: { ...form.settings, active: !currentStatus } } : form
      ));
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 3000
      });
    }
  };

  const copyFormLink = (formId) => {
    const link = `https://forms.sruve.com/f/${formId}`;
    navigator.clipboard.writeText(link);
    toast({
      title: 'Link Copied',
      description: 'Form link copied to clipboard',
      status: 'success',
      duration: 2000
    });
  };

  const fetchForms = async () => {
    try {
      const response = await fetch('https://sruve.com/api/forms', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (!response.ok) throw new Error('Failed to fetch forms');
      
      const data = await response.json();
      setForms(data);
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 3000
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchForms();
  }, []);

  if (loading) {
    return (
      <Container maxW="container.xl" py={8}>
        <VStack spacing={4}>
          <Spinner size="xl" />
          <Text>Loading forms...</Text>
        </VStack>
      </Container>
    );
  }

  return (
    <Container maxW="container.xl" py={8}>

      <HStack justify="space-between" mb={8}>
        <Heading size="lg">Your Forms</Heading>
        <HStack spacing={4}>
          <Button
            leftIcon={<FiPlus />}
            colorScheme="blue"
            onClick={() => navigate('/forms/create')}
          >
            Create New Form
          </Button>
          <IconButton
          icon={<FiArrowLeft />}
          onClick={() => navigate('/dashboard')}
          variant="ghost"
           aria-label="Back to dashboard"
          />
        </HStack>
        
      </HStack>
      

      {forms.length === 0 ? (
        <VStack spacing={4} py={8}>
          <Text>No forms created yet</Text>
          <Button
            colorScheme="blue"
            variant="outline"
            onClick={() => navigate('/forms/create')}
          >
            Create Your First Form
          </Button>
        </VStack>
      ) : (
        <SimpleGrid columns={[1, 2, 3]} spacing={6}>
          {forms.map(form => (
            <Box
              key={form._id}
              p={5}
              bg={bgColor}
              borderWidth="1px"
              borderColor={borderColor}
              borderRadius="lg"
              shadow="sm"
            >
              <VStack align="stretch" spacing={4}>
                <HStack justify="space-between">
                  <Heading size="md" isTruncated>{form.title}</Heading>
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      icon={<FiMoreVertical />}
                      variant="ghost"
                      size="sm"
                    />
                    <MenuList>
                      <MenuItem icon={<FiLink />} onClick={() => copyFormLink(form.formId)}>
                        Copy Link
                      </MenuItem>
                      <MenuItem icon={<FiTrash2 />} onClick={() => deleteForm(form.formId)}>
                        Delete
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </HStack>

                <Text noOfLines={2} color="gray.500">
                  {form.description || 'No description'}
                </Text>

                <HStack justify="space-between">
                  <Badge colorScheme={form.settings.active ? 'green' : 'red'}>
                    {form.settings.active ? 'Active' : 'Inactive'}
                  </Badge>
                  <IconButton
                    icon={form.settings.active ? <FiToggleRight /> : <FiToggleLeft />}
                    onClick={() => toggleFormStatus(form.formId, form.settings.active)}
                    variant="ghost"
                    size="sm"
                  />
                </HStack>
              </VStack>
            </Box>
          ))}
        </SimpleGrid>
      )}
    </Container>
  );
}