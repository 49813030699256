import React from 'react';
import {
  Container,
  VStack,
  Heading,
  Text,
  Button,
  Icon,
  useColorModeValue
} from '@chakra-ui/react';
import { FiCheck, FiArrowLeft } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

export default function ThankYou() {
  const navigate = useNavigate();
  const bgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <Container maxW="container.sm" py={16}>
      <VStack
        spacing={8}
        p={8}
        bg={bgColor}
        borderWidth="1px"
        borderColor={borderColor}
        borderRadius="lg"
        shadow="sm"
        align="center"
      >
        <Icon
          as={FiCheck}
          w={12}
          h={12}
          color="green.500"
          p={2}
          bg="green.100"
          borderRadius="full"
        />

        <VStack spacing={4} textAlign="center">
          <Heading size="lg">Thank You!</Heading>
          <Text color="gray.600">
            Your form has been submitted successfully. We'll process your response shortly.
          </Text>
        <Text color="gray.600" mt={4}>
          <a href="https://app.sruve.com" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: 'blue.500' }}>Create your own forms for free</a>.
        </Text>
        </VStack>
      </VStack>
    </Container>
  );
} 