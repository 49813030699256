import React from 'react';
import {
  Box,
  Container,
  Stack,
  Text,
  Link,
  ButtonGroup,
  IconButton,
  Divider,
  useColorModeValue,
} from '@chakra-ui/react';
import { FiGithub, FiTwitter, FiMessageCircle } from 'react-icons/fi';

export default function Footer() {
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  
  return (
    <Box borderTop={1} borderStyle="solid" borderColor={borderColor}>
      <Container as="footer" maxW="container.xl" py={8}>
        <Stack spacing={8}>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            spacing={{ base: 6, md: 8 }}
            align={{ base: 'flex-start', md: 'center' }}
            justify="space-between"
          >
            <Stack direction="row" spacing={6} align="center">
              <Text fontSize="sm" color="gray.500">
                © {new Date().getFullYear()} Sruve Inc. All rights reserved.
              </Text>
            </Stack>
            <ButtonGroup variant="ghost" color="gray.600">
              {/* <IconButton
                as="a"
                href="https://github.com/yourusername/logontg"
                aria-label="GitHub"
                icon={<FiGithub />}
              /> */}
              <IconButton
                as="a"
                href="https://twitter.com/sruve"
                aria-label="Twitter"
                icon={<FiTwitter />}
              />
              <IconButton
                as="a"
                href="https://t.me/LogonTG_bot"
                aria-label="Telegram"
                icon={<FiMessageCircle />}
              />
            </ButtonGroup>
          </Stack>
          
          <Divider />
          
          <Stack
            direction={{ base: 'column', md: 'row' }}
            spacing={6}
            justify="center"
            fontSize="sm"
            color="gray.600"
          >
            {/* <Link href="/docs">Documentation</Link> */}
            <Link href="https://app.sruve.com/#pricing">Pricing</Link>
            <Link href="#">Privacy Policy</Link>
            <Link href="#">Terms of Service</Link>
            <Link href="https://app.sruve.com/status">System Status</Link>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
} 