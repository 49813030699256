import React from 'react';
import {
  Alert,
  AlertIcon,
  Button,
  Stack,
  Text,
  Progress,
  Box,
  useColorModeValue,
  Icon
} from '@chakra-ui/react';
import { FiAlertCircle } from 'react-icons/fi';

export const PaywallBanner = ({ usage, limits, onUpgrade, subscription }) => {
  const bgColor = useColorModeValue('orange.50', 'orange.900');
  const borderColor = useColorModeValue('orange.200', 'orange.700');

  if (subscription?.plan === 'pro' && subscription?.status === 'active') {
    return null;
  }

  const getUsagePercentage = (used, limit) => {
    if (limit === -1) return 0;
    return Math.min((used / limit) * 100, 100);
  };

  const isApproachingLimit = (used, limit) => {
    return used >= limit * 0.8;
  };

  const showWarning = isApproachingLimit(usage.apiRequestsCount, limits.apiRequests) ||
                     isApproachingLimit(usage.formSubmissionsCount, limits.responses);

  return (
    <Box
      p={4}
      bg={bgColor}
      borderWidth="1px"
      borderColor={borderColor}
      borderRadius="lg"
      mb={6}
    >
      <Stack spacing={3} width="100%">
        <Stack direction="row" justify="space-between" align="center">
          <Stack direction="row" align="center">
            <Icon as={FiAlertCircle} color="orange.500" />
            <Text fontWeight="medium">
              {showWarning 
                ? "You're approaching your plan limits"
                : "Upgrade to Pro for unlimited access"}
            </Text>
          </Stack>
          <Button 
            colorScheme="orange" 
            size="sm" 
            onClick={onUpgrade}
            isDisabled={subscription?.status === 'pending'}
          >
            Upgrade
          </Button>
        </Stack>
        
        <Stack spacing={2}>
          <Text fontSize="sm">
            API Requests: {usage.apiRequestsCount}/{limits.apiRequests === -1 ? '∞' : limits.apiRequests}
          </Text>
          <Progress 
            value={getUsagePercentage(usage.apiRequestsCount, limits.apiRequests)}
            colorScheme={isApproachingLimit(usage.apiRequestsCount, limits.apiRequests) ? 'orange' : 'blue'}
          />
          
          <Text fontSize="sm">
            Form Submissions: {usage.formSubmissionsCount}/{limits.responses === -1 ? '∞' : limits.responses}
          </Text>
          <Progress 
            value={getUsagePercentage(usage.formSubmissionsCount, limits.responses)}
            colorScheme={isApproachingLimit(usage.formSubmissionsCount, limits.responses) ? 'orange' : 'blue'}
          />
        </Stack>
      </Stack>
    </Box>
  );
}; 