import React from 'react';
import { Select } from '@chakra-ui/react';
import BaseInput from './BaseInput';

export default function SelectInput({ field, onChange, value, error }) {
  return (
    <BaseInput
      label={field.label}
      error={error}
      helperText={field.helperText}
      isRequired={field.required}
    >
      <Select
        value={value || ''}
        onChange={(e) => onChange(field.id, e.target.value)}
        placeholder={field.placeholder}
      >
        {(field.options || []).map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </Select>
    </BaseInput>
  );
} 