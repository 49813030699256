import React from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Select,
  Radio,
  RadioGroup,
  Checkbox,
  CheckboxGroup,
  Stack,
  VStack
} from '@chakra-ui/react';

export function generateFormComponent(formConfig) {
  const FormComponent = ({ onSubmit, initialValues = {} }) => {
    const [formData, setFormData] = React.useState(initialValues);

    const handleSubmit = (e) => {
      e.preventDefault();
      onSubmit(formData);
    };

    const handleFieldChange = (fieldId, value) => {
      setFormData(prev => ({
        ...prev,
        [fieldId]: value
      }));
    };

    const renderField = (field) => {
      switch (field.type) {
        case 'text':
        case 'email':
        case 'number':
          return (
            <Input
              type={field.type}
              value={formData[field.id] || ''}
              onChange={(e) => handleFieldChange(field.id, e.target.value)}
              placeholder={field.placeholder}
              required={field.required}
            />
          );

        case 'textarea':
          return (
            <Textarea
              value={formData[field.id] || ''}
              onChange={(e) => handleFieldChange(field.id, e.target.value)}
              placeholder={field.placeholder}
              required={field.required}
            />
          );

        case 'select':
          return (
            <Select
              value={formData[field.id] || ''}
              onChange={(e) => handleFieldChange(field.id, e.target.value)}
              placeholder={field.placeholder}
              required={field.required}
            >
              {field.options.map(option => (
                <option key={option} value={option}>{option}</option>
              ))}
            </Select>
          );

        case 'radio':
          return (
            <RadioGroup
              value={formData[field.id] || ''}
              onChange={(value) => handleFieldChange(field.id, value)}
            >
              <Stack>
                {field.options.map(option => (
                  <Radio key={option} value={option}>{option}</Radio>
                ))}
              </Stack>
            </RadioGroup>
          );

        case 'checkbox':
          return (
            <CheckboxGroup
              value={formData[field.id] || []}
              onChange={(value) => handleFieldChange(field.id, value)}
            >
              <Stack>
                {field.options.map(option => (
                  <Checkbox key={option} value={option}>{option}</Checkbox>
                ))}
              </Stack>
            </CheckboxGroup>
          );

        default:
          return null;
      }
    };

    return (
      <form onSubmit={handleSubmit}>
        <VStack spacing={4} align="stretch">
          {formConfig.fields.map(field => (
            <FormControl key={field.id} isRequired={field.required}>
              <FormLabel>{field.label}</FormLabel>
              {renderField(field)}
            </FormControl>
          ))}
        </VStack>
      </form>
    );
  };

  return FormComponent;
} 