import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FormRenderer from './FormRenderer/FormRenderer';
import { Container, Spinner, Alert, AlertIcon } from '@chakra-ui/react';

export default function FormView() {
  const { slug } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    fetchForm();
  }, [slug]);

  const fetchForm = async () => {
    try {
      const response = await fetch(`https://sruve.com/api/forms/${slug}`);
      if (!response.ok) throw new Error('Form not found');
      const data = await response.json();
      setFormData(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Spinner />;
  if (error) return <Alert status="error"><AlertIcon />{error}</Alert>;

  return (
    <Container maxW="container.md" py={8}>
      <FormRenderer
        formId={slug}
        formConfig={formData}
      />
    </Container>
  );
} 