import React from 'react';
import {
  Box,
  VStack,
  Text,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Select,
  Textarea,
  Alert,
  AlertIcon,
  Icon,
} from '@chakra-ui/react';
import { FiSend } from 'react-icons/fi';

const TestLoggerSection = ({ 
  user, 
  testMessage, 
  setTestMessage, 
  logLevel, 
  setLogLevel, 
  isLoading, 
  handleTestLog,
  bgColor = 'gray.50'
}) => {
  return (
    <Box p={6} shadow="md" borderWidth="1px" borderRadius="lg" bg="white">
      <VStack spacing={6} align="stretch">
        <Text fontSize="xl" fontWeight="bold">Test Logger</Text>
        
        <FormControl>
          <FormLabel>Message</FormLabel>
          <Textarea
            value={testMessage}
            onChange={(e) => setTestMessage(e.target.value)}
            placeholder="Enter your test message here..."
            size="md"
            rows={4}
            resize="vertical"
            isDisabled={!user?.telegramId}
            bg={bgColor}
            _focus={{
              borderColor: 'blue.500',
              boxShadow: '0 0 0 1px blue.500',
            }}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Log Level</FormLabel>
          <Select
            value={logLevel}
            onChange={(e) => setLogLevel(e.target.value)}
            isDisabled={!user?.telegramId}
            bg={bgColor}
          >
            <option value="info">Info</option>
            <option value="error">Error</option>
            <option value="warning">Warning</option>
            <option value="debug">Debug</option>
          </Select>
        </FormControl>

        <Button
          leftIcon={<Icon as={FiSend} />}
          colorScheme="blue"
          onClick={handleTestLog}
          isLoading={isLoading}
          isDisabled={!user?.telegramId}
          size="md"
          width="full"
        >
          Send Test Log
        </Button>

        {!user?.telegramId && (
          <Alert status="warning" variant="left-accent">
            <AlertIcon />
            <VStack align="start" spacing={1}>
              <Text fontWeight="medium">Not Connected</Text>
              <Text fontSize="sm">
                Please connect your Telegram account to test the logger
              </Text>
            </VStack>
          </Alert>
        )}
      </VStack>
    </Box>
  );
};

export default TestLoggerSection;
