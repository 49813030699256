import React, { useState, useEffect } from 'react';
import {
  Box,
  VStack,
  HStack,
  Text,
  Button,
  useColorModeValue,
  Icon,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Divider
} from '@chakra-ui/react';
import { FiPlus, FiList } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

export default function FormCard() {
  const navigate = useNavigate();
  const { token } = useAuth();
  const [forms, setForms] = useState([]);
  const bgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  useEffect(() => {
    const fetchForms = async () => {
      try {
        const response = await fetch('https://sruve.com/api/forms', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (!response.ok) throw new Error('Failed to fetch forms');
        const data = await response.json();
        setForms(data);
      } catch (error) {
        console.error('Failed to fetch forms:', error);
      }
    };

    fetchForms();
  }, [token]);

  const activeForms = forms?.filter(form => form.settings.active)?.length || 0;
  const totalForms = forms?.length || 0;

  return (
    <Box
      p={6}
      bg={bgColor}
      borderWidth="1px"
      borderColor={borderColor}
      borderRadius="lg"
      shadow="sm"
    >
      <VStack align="stretch" spacing={4}>
        <Text fontWeight="medium" fontSize="lg">Forms</Text>
        
        <Stat>
          <StatLabel>Active Forms</StatLabel>
          <StatNumber>{activeForms}</StatNumber>
          <StatHelpText>{totalForms} Total Forms</StatHelpText>
        </Stat>

        <Text color="gray.500" fontSize="sm">
          Create custom forms and receive responses via Telegram
        </Text>

        <Divider />

        <HStack spacing={4}>
          <Button
            leftIcon={<Icon as={FiPlus} />}
            colorScheme="blue"
            variant="solid"
            size="sm"
            onClick={() => navigate('/forms/create')}
          >
            New Form
          </Button>
          <Button
            leftIcon={<Icon as={FiList} />}
            variant="ghost"
            size="sm"
            onClick={() => navigate('/forms')}
          >
            Manage Forms
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
} 