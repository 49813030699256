import React from 'react';
import {
  Box,
  Container,
  VStack,
  Heading,
  Text,
  Button,
  useColorModeValue
} from '@chakra-ui/react';
import { FiArrowLeft } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Footer from '../components/Footer';

const MotionBox = motion(Box);

export default function NotFound() {
  const navigate = useNavigate();
  
  return (
    <Box minH="100vh" bg={useColorModeValue('gray.50', 'gray.900')}>
      <Container maxW="container.xl" py={20}>
        <VStack spacing={12} align="center">
          <MotionBox
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Heading 
              size="4xl" 
              bgGradient="linear(to-r, blue.400, blue.600)"
              bgClip="text"
            >
              404
            </Heading>
          </MotionBox>
          
          <VStack spacing={4}>
            <Heading size="xl">Page Not Found</Heading>
            <Text fontSize="xl" color="gray.500" textAlign="center">
              Oops! This page seems to have wandered off.
            </Text>
          </VStack>

          <Button
            size="lg"
            leftIcon={<FiArrowLeft />}
            colorScheme="blue"
            onClick={() => navigate('/')}
          >
            Back to Home
          </Button>
        </VStack>
      </Container>
      <Footer />
    </Box>
  );
}
