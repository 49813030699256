import React from 'react';
import {
  CheckboxGroup,
  Checkbox,
  Stack,
  useColorModeValue
} from '@chakra-ui/react';
import BaseInput from './BaseInput';

export default function CheckboxInput({ field, onChange, value = [], error }) {
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const hoverBg = useColorModeValue('gray.50', 'gray.700');

  return (
    <BaseInput field={field} error={error}>
      <CheckboxGroup
        value={value}
        onChange={(vals) => onChange(field.id, vals)}
      >
        <Stack spacing={3}>
          {field.options?.map(option => (
            <Checkbox
              key={option.value}
              value={option.value}
              borderColor={borderColor}
              _hover={{ bg: hoverBg }}
            >
              {option.label}
            </Checkbox>
          ))}
        </Stack>
      </CheckboxGroup>
    </BaseInput>
  );
} 