import React, { useState, useEffect } from 'react';
import {
  VStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
  Heading,
  Divider,
  Box,
  useColorModeValue,
  Container,
  HStack,
  IconButton,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Spinner,
  Text,
  Icon,
  Badge,
  Tooltip,
  Stack
} from '@chakra-ui/react';
import { useAuth } from '../../contexts/AuthContext';
import { FiArrowLeft, FiCheck, FiClock } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { PaywallBanner } from '../PaywallBanner';

export default function ProfileSettings() {
  const navigate = useNavigate();
  const { user, updateUserProfile } = useAuth();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [isLoadingUsage, setIsLoadingUsage] = useState(true);
  const [error, setError] = useState(null);
  const bgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const proBgColor = useColorModeValue('green.50', 'green.900');
  
  const [formData, setFormData] = useState({
    email: user?.email || '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const fetchSubscriptionData = async () => {
    try {
      setError(null);
      setIsLoadingUsage(true);
      const response = await fetch('https://sruve.com/api/subscription/usage', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }
      });
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || 'Failed to fetch subscription data');
      }
      
      setSubscriptionData(data);
    } catch (error) {
      setError(error.message);
      toast({
        title: 'Error',
        description: error.message || 'Failed to fetch subscription data',
        status: 'error',
        duration: 3000
      });
    } finally {
      setIsLoadingUsage(false);
    }
  };

  useEffect(() => {
    fetchSubscriptionData();
    const interval = setInterval(fetchSubscriptionData, 60000);
    return () => clearInterval(interval);
  }, []);

  const isPro = subscriptionData?.subscription?.plan === 'pro' && 
                subscriptionData?.subscription?.status === 'active';

  const getSubscriptionStatus = () => {
    if (!subscriptionData?.subscription) return { label: 'Free', color: 'gray' };
    
    const { status, plan } = subscriptionData.subscription;
    
    if (status === 'active' && plan === 'pro') {
      return { label: 'Pro', color: 'green' };
    } else if (status === 'payment_failed') {
      return { label: 'Payment Failed', color: 'red' };
    } else if (status === 'pending') {
      return { label: 'Processing', color: 'yellow' };
    }
    
    return { label: 'Free', color: 'gray' };
  };

  const shouldShowUpgradeButton = () => {
    return !isPro && subscriptionData?.subscription?.status !== 'pending';
  };

  const handleUpgrade = async () => {
    if (subscriptionData?.subscription?.status === 'pending') return;
    setIsLoading(true);
    try {
      const response = await fetch('https://sruve.com/api/subscription/checkout', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }
      });
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || 'Failed to initiate upgrade');
      }
      
      window.location.href = data.checkoutUrl;
    } catch (error) {
      console.error('Upgrade error:', error);
      setError(error.message || 'Failed to initiate upgrade. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await updateUserProfile(formData);
      toast({
        title: 'Profile Updated',
        description: 'Your profile has been successfully updated.',
        status: 'success',
        duration: 3000
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 3000
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderBillingContent = () => {
    return (
      <Box p={8} bg={bgColor} borderRadius="lg" shadow="sm" borderWidth="1px" borderColor={borderColor}>
        <VStack spacing={6} align="stretch">
          {error && (
            <Alert status="error" mb={4}>
              <AlertIcon />
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}
          
          <Stack direction="row" justify="space-between" align="center">
            <Box>
              <HStack spacing={2} mb={1}>
                <Text fontWeight="bold" fontSize="lg">Current Plan</Text>
                <Badge colorScheme={getSubscriptionStatus().color}>
                  {getSubscriptionStatus().label}
                </Badge>
              </HStack>
              <Text color="gray.500">
                {isPro ? 'Pro' : 'Free'} Plan
              </Text>
            </Box>
            
            {shouldShowUpgradeButton() && (
              <Tooltip 
                isDisabled={subscriptionData?.subscription?.status !== 'pending'}
                label="Payment is being processed"
              >
                <Button
                  colorScheme="blue"
                  onClick={handleUpgrade}
                  isLoading={isLoading}
                  isDisabled={subscriptionData?.subscription?.status === 'pending'}
                  leftIcon={subscriptionData?.subscription?.status === 'pending' ? <FiClock /> : undefined}
                >
                  Upgrade to Pro
                </Button>
              </Tooltip>
            )}
          </Stack>

          {isLoadingUsage ? (
            <Box textAlign="center" py={4}>
              <Spinner size="xl" />
            </Box>
          ) : isPro ? (
            <Stack spacing={4} mt={6}>
              <Text fontWeight="bold">Usage Statistics</Text>
              <Box p={4} bg={proBgColor} borderRadius="md">
                <Stack spacing={3}>
                  <HStack>
                    <Icon as={FiCheck} color="green.500" />
                    <Text>Unlimited API Requests</Text>
                  </HStack>
                  <HStack>
                    <Icon as={FiCheck} color="green.500" />
                    <Text>Unlimited Form Submissions</Text>
                  </HStack>
                  <HStack>
                    <Icon as={FiCheck} color="green.500" />
                    <Text>Unlimited Everything</Text>
                  </HStack>
                  <Text fontSize="sm" color="gray.500">
                    You have unlimited access to all features with your Pro plan.
                  </Text>
                </Stack>
              </Box>
            </Stack>
          ) : subscriptionData && (
            <Stack spacing={4} mt={6}>
              <Text fontWeight="bold">Usage Statistics</Text>
              <PaywallBanner 
                usage={subscriptionData.usage}
                limits={subscriptionData.limits}
                onUpgrade={handleUpgrade}
                subscription={subscriptionData?.subscription}
              />
            </Stack>
          )}
        </VStack>
      </Box>
    );
  };

  return (
    <Container maxW="container.md" py={8}>
      <HStack justify="space-between" mb={8}>
        <Heading size="lg">Settings</Heading>
        <IconButton
          icon={<FiArrowLeft />}
          variant="ghost"
          onClick={() => navigate(-1)}
          aria-label="Go back"
        />
      </HStack>

      {!user?.telegramId && (
        <Alert status="error" mb={6} borderRadius="md">
          <AlertIcon />
          <Box>
            <AlertTitle>Telegram Not Connected</AlertTitle>
            <AlertDescription>
              Please connect your Telegram account from the dashboard to use all features.
            </AlertDescription>
          </Box>
        </Alert>
      )}

      <Tabs>
        <TabList mb={4}>
          <Tab>Profile</Tab>
          <Tab>Billing</Tab>
        </TabList>

        <TabPanels>
          <TabPanel p={0}>
            <Box
              p={8}
              bg={useColorModeValue('white', 'gray.700')}
              borderRadius="lg"
              shadow="sm"
              borderWidth="1px"
              borderColor={useColorModeValue('gray.200', 'gray.600')}
            >
              <VStack as="form" spacing={6} onSubmit={handleSubmit}>
                <Heading size="md">Profile Settings</Heading>
                <Divider />
                
                <FormControl>
                  <FormLabel>Email Address</FormLabel>
                  <Input
                    type="email"
                    value={formData.email}
                    onChange={(e) => setFormData(prev => ({
                      ...prev,
                      email: e.target.value
                    }))}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Telegram ID</FormLabel>
                  <Input
                    value={user?.telegramId || 'Not connected'}
                    isDisabled
                    bg={useColorModeValue('gray.50', 'gray.600')}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Current Password</FormLabel>
                  <Input
                    type="password"
                    value={formData.currentPassword}
                    onChange={(e) => setFormData(prev => ({
                      ...prev,
                      currentPassword: e.target.value
                    }))}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>New Password</FormLabel>
                  <Input
                    type="password"
                    value={formData.newPassword}
                    onChange={(e) => setFormData(prev => ({
                      ...prev,
                      newPassword: e.target.value
                    }))}
                  />
                </FormControl>

                <Button
                  type="submit"
                  colorScheme="blue"
                  isLoading={isLoading}
                  width="full"
                >
                  Save Changes
                </Button>
              </VStack>
            </Box>
          </TabPanel>
          <TabPanel p={0}>
            {renderBillingContent()}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  );
} 